<template>
  <div>
    <!-- BREADCRUMB -->
    <section class="breadcrumb-bg" style="background-image: url(../assets/img/background/page-title-bg-img.jpg); ">
      <div class="container">
        <div class="breadcrumb-holder">
          <div>
            <h1 class="breadcrumb-title">主題區</h1>
            <ul class="breadcrumb breadcrumb-transparent">
              <li class="breadcrumb-item">
                <a class="text-white" href="/">Home</a>
              </li>
              <li class="breadcrumb-item">
                <router-link class="text-white" to="/topic">主題區</router-link>
              </li>
              <li class="breadcrumb-item text-white active" aria-current="page">
                小馬哈影音秀
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <!-- content -->
    <section class="py-8 py-md-10">
      <div class="container">
        <router-link :to="`/video/${firstVideo.id}`" class="card">
          <div class="position-relative">
            <img class="card-img-top" :src="firstVideo.banner" alt="Card image cap">
            <div class="card-img-overlay">
              <span class="badge badge-warning badge-rounded-circle"><i class="fa fa-file-text-o" aria-hidden="true"></i></span>
            </div>
          </div>
          <div class="card-body border-top-5 px-3 rounded-bottom border-warning">
            <h3 class="card-title text-warning mb-5">
              <router-link :to="`/video/${firstVideo.id}`" class="text-warning text-capitalize d-block text-truncate">
                {{ firstVideo.title }}
              </router-link>
            </h3>
          </div>
        </router-link>
        <div class="row">
          <!-- Card -->
          <div class="owl-carousel owl-theme wow fadeInUp" id="maja-slider">
            <div class="card" v-for="item in videos" :key="item.id">
              <router-link :to="`/video/${item.id}`" class="position-relative">
                <div class="position-relative">
                  <img class="card-img-top adjust-height" :src="item.banner" alt="Card image">
                  <div class="card-img-overlay">
                    <span :class="`badge-${item.themeClass}`" class="badge badge-rounded-circle"><i class="fa fa-file-text-o" aria-hidden="true"></i></span>
                  </div>
                </div>
                <div :class="`border-${item.themeClass}`" class="card-body border-top-5 px-3 rounded-bottom">
                  <h3 class="card-title">
                    <router-link :to="`/video/${item.id}`" :class="`text-${item.themeClass}`" class="text-capitalize d-block text-truncate">
                      {{ item.title }}
                    </router-link>
                  </h3>
                </div>
              </router-link>
            </div>
          </div>
        </div>

        <section class="pt-9 py-md-7">
          <div class=" container">
            <div class="section-title justify-content-center mb-8 wow fadeInUp">
              <span class="shape shape-left bg-info"></span>
              <h2 class="text-danger">相關影音</h2>
              <span class="shape shape-right bg-info"></span>
            </div>
            <div class="list-fullwidth">
              <router-link :to="`/video/${item.id}`" class="media media-list-view mb-5" v-for="item in RelatedVideo" :key="item.id">
                <div class="media-img">
                  <img :src="item.banner" alt="Image">
                  <div class="media-img-overlay">
                    <span :class="`badge-${item.themeClass}`" class="badge badge-rounded-circle"><i class="fa fa-file-text-o" aria-hidden="true"></i></span>
                  </div>
                </div>
                <div class="media-body">
                  <h3>
                    <router-link :to="`/video/${item.id}`" :class="`text-${item.themeClass}`">
                      {{ item.title }}
                    </router-link>
                  </h3>
                  <p class="text-justify mb-lg-2">{{ item.content }}</p>
                </div>
              </router-link>
            </div>
          </div>
          <!-- 分頁 -->
          <div class="mt-8">
            <section class="py-5">
              <div class="container">
                <nav aria-label="Page navigation example">
                  <nav aria-label="Page navigation example">
                    <ul class="pagination justify-content-center align-items-center">
                      <li class="page-item" :class="{ 'disabled': currentPage === 1 }">
                        <a class="page-link" href="#" @click.prevent="getRelatedVideo(currentPage - 1)">
                        <i class="fa fa-arrow-left mr-1" aria-hidden="true"></i>
                        Prev
                        </a>
                      </li>
                      <li class="page-item" v-for="item in totalPage" :key="item" :class="{ 'active': currentPage === item }"><a class="page-link" href="#" @click.prevent="getRelatedVideo(item)">{{ item }}</a></li>
                      <li class="page-item" :class="{ 'disabled': currentPage === totalPage }">
                        <a class="page-link" href="#" @click.prevent="getRelatedVideo(currentPage + 1)">
                        Next
                        <i class="fa fa-arrow-right ml-1" aria-hidden="true"></i>
                        </a>
                      </li>
                    </ul>
                  </nav>
                </nav>
              </div>
            </section>
          </div>
        </section>
      </div>
    </section>
  </div>
</template>

<script>
/* global $ */
import tokenGenerator from '@/tokenGenerator.js'
export default {
  name: 'majashow',
  data () {
    return {
      videos: [],
      RelatedVideo: [],
      totalPage: '',
      currentPage: '',
      firstVideo: {}
    }
  },
  created () {
    const vm = this
    vm.getVideo()
    vm.getRelatedVideo()
  },
  mounted () {
    require('@/assets/js/base.js')
  },
  methods: {
    getVideo () {
      return new Promise((resolve, reject) => {
        const vm = this
        const api = `${process.env.VUE_APP_APIPATH}/iPlay/article/list`

        vm.axios.post(api, {
          token: tokenGenerator(),
          type: ['2', '3'],
          categoryId: ['15'],
          pageSize: 5,
          page: 1
        })
          .then((res) => {
            vm.videos = res.data.content
            vm.firstVideo = vm.videos[0]
            vm.videos.shift()
            vm.videos.forEach((item, index) => {
              const i = index + 1
              if (i % 3 === 1) {
                return (item.themeClass = 'warning')
              } else if (i % 3 === 2) {
                return (item.themeClass = 'success')
              } else {
                return (item.themeClass = 'danger')
              }
            })
            vm.$nextTick(() => {
              $('#maja-slider').owlCarousel({
                loop: true,
                margin: 30,
                dots: false,
                nav: true,
                navText: ['<i class="fa fa-arrow-left"></i>', '<i class="fa fa fa-arrow-right"></i>'],
                responsive: {
                  0: {
                    items: 1
                  },
                  600: {
                    items: 1
                  },
                  1000: {
                    items: 4
                  }
                }
              })
            })
            resolve()
          })
      })
    },
    getRelatedVideo (page = 1) {
      const vm = this
      const api = `${process.env.VUE_APP_APIPATH}/iPlay/article/list`

      vm.currentPage = page

      vm.axios.post(api, {
        token: tokenGenerator(),
        type: ['2', '3'],
        categoryId: ['15'],
        pageSize: 6,
        page
      })
        .then((res) => {
          vm.totalPage = res.data.totalnewsnum
          vm.RelatedVideo = res.data.content
          vm.RelatedVideo.forEach((item, index) => {
            const i = index + 1
            if (i % 6 === 1) {
              return (item.themeClass = 'warning')
            } else if (i % 6 === 2) {
              return (item.themeClass = 'success')
            } else if (i % 6 === 3) {
              return (item.themeClass = 'danger')
            } else if (i % 6 === 4) {
              return (item.themeClass = 'info')
            } else if (i % 6 === 5) {
              return (item.themeClass = 'purple')
            } else {
              return (item.themeClass = 'pink')
            }
          })
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.adjust-height {
  height: 225px;
  object-fit: cover;
}
.page-item {
  &.active {
    .page-link {
      color: #666;
    }
  }
}
</style>
